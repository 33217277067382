import React from 'react'
import { MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import Showdown from 'showdown'

class CardIcons extends React.Component {
  render() {
    const converter = new Showdown.Converter()    
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-30 d-flex" >
        <div className="card flex-fill outline card-hover">
          <div className="card-body">
            <div style={{ minWidth: '85px', maxWidth: '85px', }} >
              <GatsbyImage
                image={this.props.image}
                className="mr-1"
                alt={this.props.alt}
              />
            </div>

            <div>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium pt-3">
                {this.props.title}
              </h3>
              {this.props.subtitle && (
                <div className="font-w-400 text-medium pt-2"
                  dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.subtitle)}}
               />
              )}
              {this.props.description && (
                <div className="font-w-400 text-medium divlink pt-2"
                  dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.description)}}
               />
              )}
            </div>
          </div>
        </div>
      </MDBCol>
    )
  }
}

export default CardIcons
